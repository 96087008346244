@import "./style-config.scss";
@import "./style-functions.scss";

/* Custom pages */
.custom {
  .cover {
    position: relative;
    text-align: center;

    img {
      display: block;
      width: 100%;
      max-width: 1366px;
      object-fit: cover;
      object-position: 50% 50%;
      border-radius: 50px;

      @include custom(1366) {
        border-radius: 0;
      }

      @include sm {
        margin-top: 30px;
        height: 250px;
      }
    }
  }
}

.home, .about, .gallery {

  .home-text1,
  .about-text1,
  .gallery-text1 {
    width: 100%;
    max-width: 900px;
    margin: 70px auto;
    padding: 0 20px;
    text-align: left;
    color: $color-highlight;

    @include sm {
      text-align: center;
      margin: 40px auto;
      padding: 0 30px;
    }

    h2 {
      @include font-size(32px);
      padding-bottom: 30px;
      font-weight: normal;
    }

    p {
      max-width: 510px;
      padding: 8px 0;
      @include font-size(22px);
      line-height: 27px;
    }

    > img {
      float: right;
      margin: -100px 0 0 30px;
      position: relative;

      @include md {
        width: 230px;
      }

      @include sm {
        display: none;
      }
    }
  }

  .gallery-text1 {
    margin-bottom: 30px;

    @include sm {
      margin-bottom: 0;
      overflow: hidden;
    }

    .gallery-magazine {
      margin-top: 10px;

      p {
        @include font-size(16px);
      }

      p:last-of-type {
        @include font-size(14px);
        font-style: italic;
      }

      > a {
        background: none;
      }

      img {
        float: left;
        margin: 0 30px 0 0;
        cursor: pointer;
        @include transition(all ease-in-out 0.2s);

        &:hover {
          transform: scale(0.95);
        }
      }
    }
  }

  .gallery-photos-container {
    margin: 30px auto;
    max-width: 850px;

    img {
      width: 100%;
      cursor: pointer;
      @include transition(all ease-in-out 0.2s);

      &:hover {
        transform: scale(0.95);
      }
    }

    .gallery-photos-row {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      @include xs {
        flex-direction: column;
      }
    }

    .gallery-photos-col {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
    }

  }

  .about-text1 {
    padding: 0 50px;

    @include sm {
      padding: 0 20px;
    }

    p {
      @include font-size(18px);
      line-height: 23px;
    }

    .about-cols {
      display: flex;
      padding: 20px;
      gap: 110px;

      @include sm {
        display: block;
      }

      ul {
        list-style: circle;

        @include sm {
          list-style: none;
        }

        li {
          @include font-size(20px);
          line-height: 35px;
        }

        .li-sub {
          list-style: none;
          @include font-size(16px);
          line-height: 20px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .home-text2,
  .about-text2 {
    width: 100%;
    max-width: 600px;
    margin: 50px auto;
    text-align: center;
    color: $color-highlight;

    @include sm {
      margin: 40px auto;
      padding: 0 30px;
    }

    p {
      margin: 0 auto;
      max-width: 510px;
      @include font-size(16px);
      line-height: 23px;
      margin-bottom: 30px;
    }
  }

  .home-text3 {
    width: 100%;
    max-width: 1060px;
    margin: 50px auto;
    padding: 0 20px;
    text-align: center;
    color: $color-highlight;

    @include sm {
      text-align: center;
      margin: 40px auto;
      padding: 0 30px;
    }

    h2 {
      @include font-size(32px);
      padding: 50px 0 30px 0;
      font-weight: normal;
      text-align: left;

      @include sm {
        text-align: center;
      }
    }
  }

  .home-imgs,
  .about-imgs {
    width: 100%;
    max-width: 1000px;
    margin: 25px auto 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    gap: 25px;

    @include sm {
      display: block;
      width: 100%;
      padding: 0;
    }

    div {
      @include sm {
        margin: 20px;
      }

      img {
        width: 100%;
        object-fit: cover;
        max-width: 300px;
        border-radius: 10px;

        @include sm {
          max-width: initial;
        }
      }
    }
  }

  .home-imgs > div {
    position: relative;

    &:hover > div {
      @include opacity(1);
    }

    > div {
      @include opacity(0);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(104, 43, 124, 0.4);
      @include transition(all ease-in-out 0.2s);
      cursor: pointer;
      border-radius: 10px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        text-align: center;
        color: white;
        font-weight: bold;
        padding: 20px 20px 20px;
      }

      a:hover {
        background: none;
      }
    }
  }

  .home-select-products {
    margin: 20px 0 40px 0;
  }

  .button {
    display: inline-block;
    padding: 15px 25px;
    background-color: $color-highlight-light;
    border-radius: 20px;
    color: $color-highlight;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    @include transition(all ease-in-out 0.2s);

    &:hover {
      background-color: $color-highlight;
      color: white;
    }
  }

  a {
    color: $color-highlight;
    text-decoration: underline;
    padding: 0 5px;

    &:hover {
      text-decoration: none;
      background-color: $color-highlight-light;
      border-radius: 10px;
    }
  }
}

.gallery {

  .cover img {
    object-position: left;
  }

  .gallery-text1 img {
    width: 130px;
    margin-right: 120px;
  }
}

.legal-notice,
.terms-of-sales {
  text-align: center;

  h1 {
    color: $color-highlight;
    font-weight: normal;
    @include font-size(35px);
  }

  h2 {
    color: $color-highlight;
    font-weight: normal;
    @include font-size(25px);
    margin-bottom: 10px;
    margin-top: 30px;
  }

  h3 {
    color: $color-highlight;
    margin-bottom: 6px;
  }

  .content {
    width: 100%;
    max-width: 900px;
    margin: 50px auto;
    text-align: justify;

    @include sm {
      margin: 40px auto;
      padding: 0 30px;
    }

    p {
      margin: 0 auto;
      @include font-size(16px);
      line-height: 23px;
      margin-bottom: 10px;
    }

    img {

      width: 70%;
      margin-bottom: 10px;

      @include sm {
        width: 90%;

      }
    }
  }
}
